import { User, UserRole } from '@/Users/UserModel';
import { acquireToken, logoutSSO } from '@/auth';
import {Commit, Dispatch} from 'vuex';

export interface AuthStoreState {
    accountId: string;
    userToken: string;
    loggedInUser: User;
    isLoggedIn: boolean;
    lastRoute: string;
    isLoggingOut: boolean;
}

const auth  = {
    namespaced: true,
    state(): AuthStoreState {
        return {
            accountId: '',
            userToken: '',
            loggedInUser: { contactInformation: {} } as User,
            isLoggedIn: false,
            lastRoute: '',
            isLoggingOut: false
        };
    },
    actions: {
        async logout({ state, commit }: { commit: Commit; state: AuthStoreState }) {
            if (state.isLoggingOut) {
                return;
            }
            commit('logout');
            await logoutSSO();
            commit('clearLoggingOut');
        },
        async refreshToken({ commit, state, dispatch }: { commit: Commit; state: AuthStoreState; dispatch: Dispatch }) {
            if (!state.accountId) {
                return dispatch('logout');
            }
            const result = await acquireToken(state.accountId);
            if (result) {
                commit('setToken', { token: result.accessToken, account: result.account?.homeAccountId });
                return true;
            }
            return false;
        }
    },
    mutations: {
        setToken(state: AuthStoreState, payload: { token: string; accountId: string }) {
            state.accountId = payload.accountId;
            state.userToken = payload.token;
            if (state.userToken) {
                state.isLoggedIn = true;
            }
        },
        setLoggedInUser(state: AuthStoreState, userObject: User) {
            state.loggedInUser = userObject;
        },
        logout(state: AuthStoreState) {
            state.isLoggingOut = true;
            state.loggedInUser = { contactInformation: {} } as User;
            state.userToken = '';
            state.isLoggedIn = false;
            state.lastRoute = '';
        },
        /*
         * Also called from App.vue#created to ensure it's not persisted in local storage should something go wrong.
         * If App is being (re-)created, we're post logout
         * Otherwise future logouts won't work.
         */
        clearLoggingOut(state: AuthStoreState) {
            state.isLoggingOut = false;
        },
        setLastRoute(state: AuthStoreState, lastRoute: string) {
            state.lastRoute = lastRoute;
        }
    },
    getters: {
        token: (state: AuthStoreState) => {
            return state.userToken;
        },
        loggedInUser: (state: AuthStoreState) => {
            return state.loggedInUser;
        },
        isLoggedIn: (state: AuthStoreState) => {
            return state.isLoggedIn;
        },
        hasRole: (state: AuthStoreState) => (rolesToVerify: UserRole | UserRole[]): boolean => {
            if (!state.loggedInUser.id) {
                return false;
            }
            if (state.loggedInUser.roleId == UserRole.Admin) {
                return true;
            }
            if (Array.isArray(rolesToVerify)) {
                for (const role of rolesToVerify) {
                    if (state.loggedInUser.roleId === role) {
                        return true;
                    }
                }
                return false;
            } else {
                return state.loggedInUser.roleId === rolesToVerify;
            }
        }
    }
};
export default auth;