import {
    createProduct,
    deleteProduct,
    getProductCategories,
    getProducts,
    updateProduct
} from "@/Shopping/ShoppingService";
import {Product} from "@/Shopping/Product/ProductModel";
import {ProductCategory} from "@/Shopping/Product/ProductCategoryModel";
import {Commit} from 'vuex';

interface ProductState {
    products: Product[];
    productCategories: ProductCategory[];
}

const productStore = {
    namespaced: true,
    state(): ProductState {
        return {
            products: [] as Product[],
            productCategories: [] as ProductCategory[]
        }
    },
    actions: {
        async loadProducts({commit}: { commit: Commit; state: ProductState }) {
            const products = await getProducts();
            const categories = await getProductCategories();
            commit('setProducts', products);
            commit('setProductCategories', categories);
        },
        async createProduct({commit}: { commit: Commit; state: ProductState }, product: Product) {
            product.id = await createProduct(product);
            commit('addProduct', product);
        },
        async updateProduct({commit, state}: { commit: Commit; state: ProductState }, toUpdate: Product) {
            const index = state.products.indexOf(toUpdate);
            await updateProduct(toUpdate.id, toUpdate);
            commit('updateProduct', { index: index, toUpdate: toUpdate });
        },
        async deleteProduct({commit, state}: { commit: Commit; state: ProductState }, product: Product) {
            const index = state.products.indexOf(product);
            commit('removeProduct', index);
            await deleteProduct(product.id);
        }
    },
    mutations: {
        setProducts(state: ProductState, payload: Product[]) {
            state.products = payload;
        },
        setProductCategories(state: ProductState, payload: ProductCategory[]) {
            state.productCategories = payload;
        },
        addProduct(state: ProductState, payload: Product) {
            state.products.push(payload);
        },
        updateProduct(state: ProductState, payload: { index: number; toUpdate: Product }) {
            state.products.splice(payload.index, 1, payload.toUpdate);
        },
        removeProduct(state: ProductState, payload: { index: number; toUpdate: Product }) {
            state.products.splice(payload.index, 1);            
        }        
    },
    getters: {
        products: (state: ProductState) => state.products,
        productCategories: (state: ProductState) => state.productCategories,
        examProducts: (state: ProductState) => state.products.filter((product: Product) => Boolean(product)  && product.productCategoryId === 2),
        applicationProducts: (state: ProductState) => state.products.filter((product: Product) => Boolean(product) && product.productCategoryId === 3)
    }
};
export default productStore;