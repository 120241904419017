import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth, { AuthStoreState } from '@/Users/AuthStore';
import ShoppingStore from '@/Shopping/ShoppingStore';
import product from '@/Shopping/Product/ProductStore'
import application from '@/Applications/ApplicationStore';

export const store = createStore<AppState>({
    modules: {
        application,
        auth,
        product,
        ShoppingStore
    },
    plugins: [createPersistedState({
        paths: [ 'auth', 'ShoppingStore','product' ]
    })]
});

export interface AppState {
    auth: AuthStoreState;
}
