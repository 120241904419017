import { Product, ProductCount } from "@/Shopping/Product/ProductModel";
import { DELETE, GET, POST, PUT } from "@/utils/HTTP";
import { Order } from '@/Shopping/CartItemModel';
import {ProductCategory} from "@/Shopping/Product/ProductCategoryModel";
import {FeeModel} from '@/Shopping/FeeModel';

export function getProducts(): Promise<Product[]> {
    return GET('/products/');
}

export function deleteProduct(id: string) {
    return DELETE(`/products/${id}/`);
}

export function createProduct(product: Product): Promise<string> {
    return POST(`/products`, product) as Promise<string>;
}

export function updateProduct(id: string, product: Product) {
    return PUT(`/products/${id}`, product);
}

export function getProductCategories(): Promise<ProductCategory[]> {
    return GET('/products/categories');
}

export function getProductCounts(): Promise<ProductCount[]> {
    return GET('/products/counts');
}

export function hasProductBeenPurchased(id: string): Promise<boolean> {
    return GET(`/products/${id}/was-purchased`);
}

export async function checkout(order: Order): Promise<string> {
    const { redirectUrl } = await POST(`/carts/create-checkout-session`, order) as {redirectUrl: string};
    return redirectUrl;
}
   
export function getCartFees(order: Order): Promise<FeeModel> {
    return POST('/carts/cart-fees',order) as Promise<FeeModel>;
}