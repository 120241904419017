import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { store } from "@/store/Store";
import { UserRole } from "@/Users/UserModel";
import { presentAlert } from '@/utils/Notify';

const LOGIN_NAME = 'Login';
const LOGIN_RESPONSE_NAME = 'LoginResponse';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: LOGIN_NAME,
        component: () => import ('../Login/Login.vue'),
        meta: { public: true }
    },
    {
        path: '/Inactive',
        name: 'Inactive',
        component: () => import('../Login/InactiveLogin.vue'),
        meta: { public: true }
    },
    {
        path: '/reset-password',
        name: 'resetPassword',
        component: () => import('../Login/ResetPassword/ResetPassword.vue'),
        props: (route) => ({ token: route.query.token }),
        meta: { public: true }
    },
    {
        path: '/response-oidc',
        name: LOGIN_RESPONSE_NAME,
        component: () => import('../Login/LoginResponse.vue'),
        meta: { public: true }
    },
    {
        path: "/",
        name: "Home",
        component: () => import('../Home.vue'),
    },
    {
        path: "/",
        name: "InspectorHome",
        component: () => import('../Inspector/Home.vue'),
        meta: { roles: UserRole.Inspector }
    },
    {
        path: "/",
        name: "AdminHome",
        component: () => import('../Admin/Home.vue'),
        meta: { roles: UserRole.Admin }
    },
    {
        path: "/",
        name: "LicenseHolderHome",
        component: () => import('../LicenseHolders/Home.vue'),
    },
    {
        path: '/certificate-print/:id',
        name: 'PrintCertificate',
        component: () => import('../Certificates/PrintCertificate.vue'),
        props: true
    },
    {
        path: '/certificates/:id/:contactId?',
        name: 'Certificate',
        component: () => import('../Certificates/Certificate.vue'),
        props: true
    },
    {
        path: '/certificates/search',
        name: 'CertificateSearch',
        component: () => import('../Certificates/Search.vue'),
        props: true
    },
    {
        path: '/personal/:id',
        name: 'Personal',
        component: () => import('../Users/Personal.vue'),
        props: true
    },
    {
        path: '/user/search',
        name: 'AdminUserSearch',
        component: () => import('../Users/UserSearch.vue'),
        props: true,
        meta: { roles: [UserRole.Admin, UserRole.Inspector] }
    },
    {
        path: '/admin/user/:id/:userId',
        name: 'AdminEditUser',
        component: () => import('../Users/Personal.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    },
    {
        path: '/companies/:id/:userId',
        name: 'AdminEditCompany',
        component: () => import('../Users/Personal.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    },
    {
      path: "/admin/view-user/:id",
      name: "AdminLicenseHolderHome",
      component: () => import('../LicenseHolders/Home.vue'),
      props: true,
      meta: { roles: [UserRole.Admin, UserRole.Inspector] }
    },
    {
        path: '/licenses/:licenseId/renew/personal',
        name: 'LicenseVerifyPersonal',
        component: () => import('../Users/Personal.vue'),
        props: true
    },
    {
        path: '/licenses/:typeId/personal',
        name: 'LicenseApplicationVerifyPersonal',
        component: () => import('../Users/Personal.vue'),
        props: true
    },
    {
        path: '/licenses/:typeId/user/:id',
        name: 'LicenseApplicationVerifyContact',
        component: () => import('../Users/Personal.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    },
    {
        path: '/companies/:id',
        name: 'Company',
        component: () => import('../Users/Personal.vue'),
        props: true
    },
    {
        path: '/licenses/search',
        name: 'LicenseSearch',
        component: () => import('../Licenses/SearchLicenses.vue'),
        props: true,
        meta: { public: true }
    },
    {
        path: '/licenses/:id/print',
        name: 'PrintLicense',
        component: () => import('../Licenses/PrintLicense.vue'),
        props: true
    },
    {
        path: '/licenses/admin/:id/print',
        name: 'PrintAdminLicense',
        component: () => import('../Licenses/PrintAdminLicense.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    },
    {
        path: '/licenses/:id/details',
        name: 'EditLicense',
        component: () => import('../Licenses/License.vue'),
        props: true,
        meta: { roles: [UserRole.Admin, UserRole.Inspector] }
    },
    {
        path: '/licenses/applications/',
        name: 'LicenseOptions',
        component: () => import('../Licenses/Options.vue'),
        props: true
    },
    {
        path: '/licenses/applications/for/:contactId',
        name: 'LicenseOptionsFor',
        component: () => import('../Licenses/Options.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    },
    {
        path: '/licenses/applications/search',
        name: 'ApplicationSearch',
        component: () => import('../Applications/Search.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    },
    {
        path: '/licenses/:typeId/application/',
        name: 'LicenseApplication',
        component: () => import('../Applications/LicenseApplication.vue'),
        props: true
    },
    {
        path: '/licenses/:typeId/application/:applicationId/:contactId',
        name: 'LicenseApplicationFor',
        component: () => import('../Applications/LicenseApplication.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    },
    {
        path: '/licenses/:typeId/application/:applicationId',
        name: 'LicenseApplicationAdmin',
        component: () => import('../Applications/LicenseApplication.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    },
    {
        path: '/licenses/:typeId/:licenseId/timeline',
        name: 'RenewLicense',
        component: () => import('../Renewals/RenewLicense.vue'),
        props: true
    },
    {
        path: '/licenses/:typeId/:licenseId/timeline',
        name: 'RenewPlumbingLicense',
        component: () => import('../Renewals/RenewPlumbingLicense.vue'),
        props: true
    },
    {
        path: '/licenses/:typeId/:licenseId/timeline',
        name: 'RenewWaterConditioningLicense',
        component: () => import('../Renewals/RenewWaterConditioningLicense.vue'),
        props: true
    },
    {
        path: '/licenses/:typeId/:licenseId/timeline',
        name: 'RenewSewerWaterLicense',
        component: () => import('../Renewals/RenewSewerWaterLicense.vue'),
        props: true
    },
    {
        path: '/licenses/:licenseId/renew',
        name: 'RenewLicenseForm',
        component: () => import('../Renewals/RenewLicenseForm.vue'),
        props: true
    },
    {
        path: '/renew/search',
        name: 'RenewalSearch',
        component: () => import('../Renewals/SearchRenewals.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    },
    {
        path: '/licenses/:licenseId/renew/:id',
        name: 'RenewLicenseReview',
        component: () => import('../Renewals/RenewLicenseReview.vue'),
        props: true
    },
    {
        path: '/exams/:id',
        name: 'Exam',
        component: () => import('../Exams/Exam.vue'),
        props: true
    },
    {
        path: '/exams/:id/:contactId',
        name: 'ExamFor',
        component: () => import('../Exams/Exam.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    },
    {
        path: '/exam-success/:id',
        name: 'ExamRegistered',
        component: () => import('../Exams/ExamRegistered.vue'),
        props: true
    },
    {
        path: '/available-exams',
        name: 'ExamSearch',
        component: () => import('../Exams/ExamSearch.vue'),
        meta: { roles: UserRole.Admin }
    },
    {
        path: '/available-exams/:id',
        name: 'ExamDetails',
        component: () => import('../Exams/ExamDetails.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    }, {
        path: '/cart',
        name: 'Cart',
        component: () => import('../Shopping/Cart/CartView.vue'),
        meta: { public: true }
    },
    {
        path: '/cart/:status',
        name: 'CartComplete',
        component: () => import('../Shopping/Cart/CartComplete.vue'),
        props: true
    },
    {
        path: '/products',
        name: 'Products',
        component: () => import('../Shopping/Product/ProductSearch.vue'),
        props: true,
        meta: { public: true }
    },
    {
        path: '/product/:productId',
        name: 'ProductEdit',
        component: () => import('../Shopping/Product/ProductEditComponent.vue'),
        props: true
    },
    {
        path: '/products/shop',
        name: 'Shop',
        component: () => import('../Shopping/Product/ProductSearch.vue'),
        props: true,
        meta: { public: true }
    },
    {
        path: '/products/course',
        name: 'CECorrespondenceCourse',
        component: () => import('../Shopping/Product/CECorrespondenceCourse.vue'),
        props: true,
        meta: { public: true }
    },
    {
        path: '/user/:contactId/ce/new',
        name: 'NewContinuingEducation',
        component: () => import('../ContinuingEd/ContinuingEducation.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    },
    {
        path: '/user/:contactId/ce/:id',
        name: 'ViewContinuingEducation',
        component: () => import('../ContinuingEd/ContinuingEducation.vue'),
        props: true,
        meta: { roles: UserRole.Admin }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

router.beforeEach((to, from, next) => {
    setRedirect(to);
    if (to.matched.some(record => record.meta.public)) {
        next();
    } else if (!store.state.auth.isLoggedIn) {
        next({ name: LOGIN_NAME });
    } else if (to.meta.roles && !store.getters['auth/hasRole'](to.meta.roles)) {
        presentAlert('You are not authorized to view this screen');
        return next({ name: 'Home' });
    } else {
        next();
    }
});

/*
 * Set the last "from" route in vuex
 * This will be used on login to re-navigate to the user's last view.
 */
function setRedirect(route: RouteLocationNormalized) {
    if (route.name === LOGIN_NAME || route.name === LOGIN_RESPONSE_NAME) {
        return;
    }
    store.commit('auth/setLastRoute', route.fullPath);
}


export default router
