import { notify } from '@kyvg/vue3-notification';
import { getError } from '@/errors/PortalError';

export function presentSuccess(message: string) {
    notify({
        title: 'Success',
        text: message,
        type: 'success'
    });
}

export function presentAlert(message = '') {
    notify({
        title: 'Alert',
        text: message,
        type: 'warn'
    });
}

export function presentError(message = '') {
    notify({
        title: 'Error',
        text: message,
        type: 'error'
    });
}

export function presentException(err: Error, defaultMessage?: string) {
    presentError(getError(err, defaultMessage));
}