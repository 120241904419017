import {EMPTY_GUID} from "@/utils/GuidUtil";
import {ContactInformation} from "@/ContactInformation/ContactInformationModel";

export enum UserRole {
    Public = 0,
    Licensee = 1,
    Inspector = 2,
    Admin = 3
}

export class User {
    id = EMPTY_GUID
    email = '';
    active = false;
    roleId = UserRole.Public;
    contactInformation = new ContactInformation();
    isInGoodStanding = false;
    canSubmitCertificate = false;
    fullName = '';
}

export class RoleModel {
    id = 0;
    name = '';
}