import {EMPTY_GUID} from "@/utils/GuidUtil";
import {TradeSchool} from "@/TradeSchool/TradeSchoolModel";

export class ContactInformation {
    id = EMPTY_GUID;
    addressLine1 = '';
    addressLine2 = '';
    city = '';
    state = '';
    zip = '';
    personalPhone = '';
    ssn = '';
    birthDate: string | null;
    firstName = '';
    lastName = '';
    fullName = '';
    businessName = '';
    businessPhone = '';
    businessAddressLine1 = '';
    businessAddressLine2 = '';
    businessCity = '';
    businessState = '';
    businessZip = '';
    businessFax = '';
    tradeSchoolId = null;
    tradeSchool = new TradeSchool;
    userId = EMPTY_GUID;
    standing = false;
    canSubmitCertificate = false;
    active = false;
    
    email = '';
    
    constructor() {
        this.birthDate = null;
    }
}