import { store } from '@/store/Store';
import router from '@/router';
import { presentAlert } from '@/utils/Notify';
import {PortalError} from '@/errors/PortalError';

enum HttpMethod {
    POST = 'POST',
    PUT = 'PUT',
    GET = 'GET',
    DELETE = 'DELETE'
}


// As use cases arise of other HTTP Response Statuses containing a body, they can be added to this list.
const ResponsesWithBody = new Set<number>([200]);

export function POST<T>(url: string, data = {}): Promise<T | undefined> {
    return makeRequest(url, HttpMethod.POST, { body: JSON.stringify(data) }) as Promise<T>;
}

export function FILEUPLOAD<T>(url: string, data: FormData): Promise<T | undefined> {
    return makeRequest(url, HttpMethod.POST, { headers: headers(true), body: data }) as Promise<T>;
}

export async function PUT(url: string, data = {}): Promise<void> {
    await makeRequest(url, HttpMethod.PUT, { body: JSON.stringify(data) });
}

export function GET<T>(url: string, blobResponse = false): Promise<T> {
    return makeRequest(url, HttpMethod.GET, {}, blobResponse) as Promise<T>;
}

export async function FILEDOWNLOAD(url: string, blobResponse = false): Promise<{ blob: Blob, headers: Headers } | undefined> {
    const response = await makeRequest(url, HttpMethod.GET, {}, blobResponse);
    if (response && response instanceof Response) {
        const blob = await response.blob();
        return {blob, headers: response.headers};
    } else {
        throw new PortalError('Invalid Response');
    }
}

export async function DELETE(url: string): Promise<void> {
    await makeRequest(url, HttpMethod.DELETE);
}


async function makeRequest<T>(url: string, method: HttpMethod, options: RequestInit = {}, 
                              blobResponse = false, isFirstCall = true): Promise<T | undefined | Response> {
    const fetchUrl = process.env.VUE_APP_BASE_URL + url;

    const response = await fetch(fetchUrl, {
        method: method,
        headers: headers(),
        ...options
    });

    if (ResponsesWithBody.has(response.status)) {
        if (blobResponse) {
            return response;
        }
        return response.json();
    } else if (!response.ok) {
        if (response.status === 401 && isFirstCall) {
            return await store.dispatch('auth/refreshToken');
        } else if (isFirstCall) {
            await makeRequest(url, method, options, blobResponse, false);
        }
        throw await handleBadResponse(response);
    }
}

function headers(isFileUpload= false) {
    const allHeaders: { [id: string]: string } = {
        Accept: 'application/json, */*',
    };

    if (!isFileUpload) {
        allHeaders['Content-Type'] = 'application/json';
    }

    const token = store.getters['auth/token'];
    if (token !== '') {
        allHeaders.Authorization = 'Bearer ' + token;
    }
    return allHeaders;
}

/**
 * Handles any unexpected responses from an API call. Supports retrying unauthorized requests once in case the token has expired.
 *
 * @param response to handle
 * @param isFirstCall if this is the first attempt for this API call. The first time, we'll try to refresh the token if it's expired.
 */
async function handleBadResponse(response: Response): Promise<boolean> {
    if (response.status === 401) {
        presentAlert('Your session has expired');
        await store.dispatch('auth/logout');
        await router.push({ name: 'Login'});
    } else if (response.status === 429) {
        // Re-throw the 429 to the caller so they can handle the error
        return false;
    }

    const text = await response.text();
    if (text) {
        const json = JSON.parse(text);
        if (json.detail) {
            throw new Error(json.detail);
        }
    }

    throw new Error('Something went wrong');
}