import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './theme/global.css';
import { store } from '@/store/Store';
import Notifications from '@kyvg/vue3-notification';

createApp(App)
    .use(router)
    .use(store)
    .use(Notifications)
    .mount('#app');
