export class PortalError extends Error {
  public displayError?: string;

  constructor(message: string, displayError?: string) {
    super(message);
    if (displayError) {
      this.displayError = displayError;
    }
  }
}

export function getError(err: unknown, defaultMsg = 'An error occurred.'): string {
    if (typeof err === 'string') {
        return err;
    } else if (err instanceof PortalError && err.displayError) {
        return err.displayError;
    } else if (err instanceof Error && err.message) {
        return err.message;
    }
    // eslint:disable-next-line:no-console
    console.error(err);
    return defaultMsg;
}
