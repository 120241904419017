export function toSentenceCase(input: string) {
    const endOfSentence = '.'; 
    let foundEndOfSentence = false;
    return input.charAt(0).toUpperCase() + [...input.substring(1)].map((char) => {
        if (char === endOfSentence) {
            foundEndOfSentence = true;
        } else if (foundEndOfSentence && char.match(/^[^\n ]*$/)) {
            foundEndOfSentence = false;
            return char.toUpperCase();
        }
        
        return char;
    }).join('');
}