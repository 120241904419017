import { Application } from '@/Applications/ApplicationModel';

export interface ApplicationStoreState {
    redirectApplication: Application | null
}

const applicationStore = {
    namespaced: true,
    state(): ApplicationStoreState {
        return {
            redirectApplication: null
        };
    },
    mutations: {
        setRedirectApplication(state: ApplicationStoreState, redirectApplication: Application | null) {
            state.redirectApplication = redirectApplication;
        }
    }
};
export default applicationStore;
