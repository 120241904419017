import * as msal from "@azure/msal-browser";
import { presentError } from '@/utils/Notify';
import {AccountInfo, AuthenticationResult, InteractionRequiredAuthError, LogLevel} from '@azure/msal-browser';
import { store } from '@/store/Store';
import router from "@/router";

const msalConfig = {
    auth: {
        clientId: process.env.VUE_APP_AD_CLIENT_ID  || '',
        authority: process.env.VUE_APP_AUTHORITY,
        knownAuthorities: [process.env.VUE_APP_KNOWN_AUTHORITIES || ''],
        redirectUri: process.env.VUE_APP_REDIRECT_URI
    },
    cache: {
        cacheLocation: "localStorage"
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case msal.LogLevel.Error:
                        console.error(message);
                        return;
                    case msal.LogLevel.Info:
                        console.info(message);
                        return;
                    case msal.LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case msal.LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            piiLoggingEnabled: false,
            logLevel: Number(process.env.VUE_APP_MSAL_LOG_LEVEL)
        }
    }
};


export async function logoutSSO() {
    try {
        await msalInstance.initialize();
        await msalInstance.logoutRedirect();
    } catch (e) {
        console.error('logout error', e);
        presentError('Error trying to logout');
        //Do not throw, current implementation of InteractionRequiredAuthError error handling requires logout.
    }
}

/**
 * Gets the account ID the user has logged in with. If the user hasn't logged in yet, it handles redirecting to the login page.
 * If multiple users have logged in, it logs all out so the user can start over (we don't support logging in with multiple accounts simultaneously).
 */
export async function getAccountId(): Promise<string | undefined> {
    await msalInstance.initialize();
    const currentAccounts = msalInstance.getAllAccounts();
    if (currentAccounts.length === 0) {
        await msalInstance.loginRedirect({
            redirectUri: process.env.VUE_APP_REDIRECT_URI,
            scopes: AUTH_SCOPES
        });
    } else if (currentAccounts.length === 1) {
        return currentAccounts[0].homeAccountId;
    } else {
        const accounts = currentAccounts.filter(isAccountForMario);
        if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
            return accounts[0].homeAccountId;
        } else {
            // Multiple users detected. Logout all to be safe.
            await store.dispatch('auth/logout');
        }
    }
}

function isAccountForMario(account: AccountInfo): boolean {
    return account.homeAccountId.toUpperCase().includes((process.env.VUE_APP_POLICY_NAME || '').toUpperCase()) &&
        (account.idTokenClaims!.iss || '').toUpperCase().includes((process.env.VUE_APP_AUTHORITY_ISSUER || '').toUpperCase()) &&
        account.idTokenClaims!.aud === process.env.VUE_APP_AD_CLIENT_ID;
}

export async function acquireToken(accountId: string): Promise<AuthenticationResult | undefined> {
    try {
        console.log('AcquireToken');
        await msalInstance.initialize();
        const result = await msalInstance.acquireTokenSilent({
            account: msalInstance.getAccount(  {homeAccountId: accountId })!,
            scopes: AUTH_SCOPES
        });
        if (result.accessToken && (result.expiresOn?.getTime() ?? 0) > Date.now()) {
            console.log('Got Silent Token.');
            return result;
        } else {
            await acquireNewToken(accountId);
        }
    } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
                
            // should be acquireNewToken for seamless UX. However, to avoid redoing publicly re-used pages, logout.
            // TO DO: Move all auth logic from login.vue to a logged in vue wrapper.
            // Cause - Silent Token error to Acquire token success > MS redirects back to the page that prompted the
            // login flow. Therefore every page would need to implement the login flow. (Suggestion - implement from 
            // the router.)
                
            await logoutSSO();
            await router.push({name: 'Login'});
        } else {
            throw e;
        }
    }
}

// This will acquire the token via Redirect, which will be handled by the Redirect URL "Login.vue"
async function acquireNewToken(accountId: string) {
    console.log('AcquireNewToken');
    await msalInstance.acquireTokenRedirect({
        account: msalInstance.getAccount(  {homeAccountId: accountId })!,
        scopes: AUTH_SCOPES
    });
}

export const msalInstance = new msal.PublicClientApplication(msalConfig);
// Need to pass the Client ID to scopes for Access Tokens to be returned
export const AUTH_SCOPES = ['openid', 'profile', process.env.VUE_APP_AD_CLIENT_ID || ''];