import { CartItem } from "@/Shopping/CartItemModel";
import { presentError } from '@/utils/Notify';
import {EMPTY_GUID} from '@/utils/GuidUtil';
import {Commit} from 'vuex';

interface ShoppingStoreState {
    cartItems: CartItem[];
}

const shoppingStore = {
    namespaced: true,
    state (): ShoppingStoreState {
        return {
            cartItems: [] as CartItem[]
        }
    },
    actions: {
        addToCart({commit, state}: { commit: Commit; state: ShoppingStoreState }, cartItem: CartItem) {
            if(!cartItem || !cartItem.product){
                presentError('Product not selected');
                return;
            }

            const index = getIndex(state.cartItems, cartItem.product.id);
            if (index === -1 || state.cartItems[index].associatedNonStoreId) {
                commit('addCartItem', cartItem);
            } else {
                const toUpdate = state.cartItems[index];
                toUpdate.quantity++;
                commit('updateCartItem', { index, toUpdate });
            }
        },
        removeItem({commit, state}: { commit: Commit; state: ShoppingStoreState }, cartItem: CartItem) {
            const index = getIndex(state.cartItems, cartItem.product.id);
            commit("removeCartItem", index);
            const relatedIndexes = getRequiredForIndexes(state.cartItems,cartItem.associatedNonStoreId);
            for(const index of relatedIndexes) {
                commit("removeCartItem", index);
            }
        },
        updateItem({commit, state}: { commit: Commit; state: ShoppingStoreState }, cartItem: CartItem) {
            const index = getIndex(state.cartItems, cartItem.product.id);
            commit("updateCartItem", { index: index, toUpdate: cartItem });
        }
    },
    mutations: {
        addCartItem(state: ShoppingStoreState, payload: CartItem) {
            state.cartItems.push(payload);
        },
        removeCartItem(state: ShoppingStoreState, index: number) {
            state.cartItems.splice(index, 1);
        },
        updateCartItem(state: ShoppingStoreState, payload: { index: number; toUpdate: CartItem }) {
            state.cartItems.splice(payload.index, 1, payload.toUpdate);
        },
        clearCart(state: ShoppingStoreState) {
            state.cartItems = [];
        }
    },
    getters: {
        cartItems: (state: ShoppingStoreState) => state.cartItems,
        cartCount: (state: ShoppingStoreState) => state.cartItems.map((item: CartItem) => item.quantity).reduce((prev: number, next: number) => prev + next)
    }
};

export default shoppingStore;

function getIndex(cartItems: CartItem[], id: string): number {
    for (let index = 0; index < cartItems.length; index++) {
        if (cartItems[index].product.id === id) {
            return index;
        }
    }
    return -1;
}

//Returns an array of indexes sorted in descending order so they can be removed from the end.
function getRequiredForIndexes(cartItems: CartItem[], id: string): number[] {
    const toReturn: number[] = [];
    if(id === '' || id === EMPTY_GUID) {
        return toReturn;
    }
    for(let index = cartItems.length - 1; index >= 0; index--) {
        if(cartItems[index].requiredForId === id) {
            toReturn.push(index);
        }
    }
    return toReturn;
}